<template>
  <div>
    <div class="d-flex mb-16">
      <div class="tile tile--headline">
        <div class="tile__image">
          <toko-plan-icon />
        </div>
        <p class="tile__content text-bold text-uppercase">Plans</p>
      </div>

      <router-link class="btn btn--primary" to="/plans/new" tag="button"> Add Plan </router-link>
    </div>

    <div v-if="plans" class="table-wrapper table-wrapper--fluid">
      <table class="table table--draggable">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Plan</th>
            <th>Interest Rate</th>
            <th>Min. Amount</th>
            <th>Max. Amount</th>
            <th>Hold Period</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <draggable v-model="plans" tag="tbody" v-bind="dragOptions" handle=".drag-handler" @update="onDragUpdate()">
          <router-link
            v-for="plan in plans"
            :key="plan.id"
            :to="{ name: 'Plan', params: { planId: plan.id } }"
            class="link--clone"
            tag="tr"
          >
            <td></td>
            <td>
              <span class="drag-handler">
                <toko-burger-icon />
              </span>
            </td>
            <td>
              <!-- <span class="tag tag--success">Active</span> -->
              <p>{{ plan.title }}</p>
            </td>
            <td>{{ plan.interests || '-' }}</td>
            <td>{{ plan.min_amount | number_with_commas }}</td>
            <td>{{ plan.max_amount | number_with_commas | maxAmount }}</td>
            <td>{{ plan.period | period }}</td>
            <td>-</td>
            <td></td>
          </router-link>
        </draggable>
      </table>
    </div>

    <no-data v-else title="No plan available">
      <p class="mt-24">Start adding your first plan</p>
    </no-data>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
const NoData = () => import('@/components/NoData')
const TokoPlanIcon = () => import('@/ui/toko-icons/TokoPlan')
const TokoBurgerIcon = () => import('@/ui/toko-icons/TokoBurger')

export default {
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: 'plans',
        disabled: false
      },
      plans: null
    }
  },

  components: {
    draggable,
    NoData,
    TokoPlanIcon,
    TokoBurgerIcon
  },

  filters: {
    period(value) {
      const period = value > 1 ? `${value} months` : `${value} month`
      return period
    },

    maxAmount(value) {
      const maxAmount = value ? value : 'Unlimited'
      return maxAmount
    }
  },

  computed: {
    ...mapGetters('staking', ['getStakingPlansOrdered']),

    planIdsOrdered() {
      return this.plans.map(plan => plan.id)
    }
  },

  methods: {
    ...mapActions('staking', ['getStakingPlans']),

    onDragUpdate() {
      this.$lStorage.set('stakingPlanIdsOrdered', JSON.stringify(this.planIdsOrdered))
    }
  },

  created() {
    this.getStakingPlans().then(() => {
      this.plans = this.getStakingPlansOrdered
    })
  }
}
</script>
